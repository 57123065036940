import { ENERGY_TYPES, ENERGY_TYPE_GROUPS } from '../models/energy-types.models'
import * as Sentry from '@sentry/angular-ivy'

const ENERGY_TYPES_DICTIONARY: {
  [energyTypeGroup: string]: string
} = ENERGY_TYPES.concat(ENERGY_TYPE_GROUPS).reduce((acc, energyTypeCode) => {
  switch (energyTypeCode) {
    case 'REGULAR':
      acc[energyTypeCode] = 'Unleaded Regular'
      break
    case 'PREMIUM':
      acc[energyTypeCode] = 'Unleaded Premium'
      break
    case 'DIESEL':
      acc[energyTypeCode] = 'Diesel Conventional'
      break
    case 'RD80':
    case 'RD95':
    case 'RD99':
    case 'RENEWABLE_DIESEL':
      acc[energyTypeCode] = 'Diesel Renewable'
      break
    case 'DYED_DIESEL':
      acc[energyTypeCode] = 'Dyed Diesel'
      break
    case 'E85':
    case 'ETHANOL':
      acc[energyTypeCode] = 'Ethanol (E85)'
      break
    default:
      acc[energyTypeCode] = energyTypeCode
        .split('_')
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
      break
  }
  return acc
}, {})

export function getEnergyTypeLabel(energyType: string): string {
  return ENERGY_TYPES_DICTIONARY[energyType]
}

export function getFuelGroup(energyType: string): 'GAS' | 'DIESEL' | undefined {
  switch (energyType) {
    case 'REGULAR':
    case 'PREMIUM':
      return 'GAS'
    case 'DIESEL':
    case 'RD80':
    case 'RD95':
    case 'RD99':
    case 'RENEWABLE_DIESEL':
    case 'DYED_DIESEL':
      return 'DIESEL'
    default:
      Sentry.captureEvent({
        message: `getFuelGroup() Unhandled EnergyType: ${energyType}`,
      })
      return undefined
  }
}
